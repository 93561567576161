<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Отчётность</h1>
      <div v-if="userRole === 'admin'" class="search">
        <v-select
          @search="onSearch"
          @input="onSetOrganization"
          v-model="region_result"
          :reduce="org => org.id"
          :filterable="false"
          :clearable="false"
          :options="region_options"
          :get-option-label="getLabel"
          placeholder="Выберите региональную организацию"
          class="select"
        >
          <template slot="open-indicator">
            <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                 fill="none">
              <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                    stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
          </template>
          <template slot="no-options">Введите свой запрос для поиска</template>
          <template slot="option" slot-scope="option">
            <div class="select__item d-center">
              {{ option.name }} (ID: {{ option.id }}) ({{ option.group_name }})
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.name }} (ID: {{ option.id }}) ({{ option.group_name }})
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <div class="report">
      <div v-if="userRegion" class="report__list">
        <a :href="$router.resolve({ name: 'head' }).href" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>1</span>
          </div>
          <div class="report__title">Анкета руководителя</div>
        </a>
        <router-link :to="{ name: 'passport' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>2</span>
          </div>
          <div class="report__title">Паспорт РО РСМ</div>
        </router-link>
        <router-link :to="{ name: 'local' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>3</span>
          </div>
          <div class="report__title">Местные организации</div>
        </router-link>
        <router-link :to="{ name: 'primary' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>4</span>
          </div>
          <div class="report__title">Первичные группы</div>
        </router-link>
        <router-link :to="{ name: 'meeting' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>5</span>
          </div>
          <div class="report__title">Заседания руководящих органов</div>
        </router-link>
        <router-link :to="{ name: 'justice' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>6</span>
          </div>
          <div class="report__title">Отчетность в территориальные органы Минюста РФ</div>
        </router-link>
        <router-link :to="{ name: 'balance' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>7</span>
          </div>
          <div class="report__title">Бухгалтерский баланс</div>
        </router-link>
        <router-link :to="{ name: 'program' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>8</span>
          </div>
          <div class="report__title">Уведомление о реализации центральных программ и проектов</div>
        </router-link>
        <router-link :to="{ name: 'information' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>9</span>
          </div>
          <div class="report__title">Информационное обеспечение деятельности</div>
        </router-link>
        <router-link :to="{ name: 'agreement' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>10</span>
          </div>
          <div class="report__title">Соглашения о сотрудничестве с партнерами</div>
        </router-link>
        <router-link :to="{ name: 'grant' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>11</span>
          </div>
          <div class="report__title">Грантовые конкурсы, субсидии</div>
        </router-link>
        <router-link :to="{ name: 'school' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>12</span>
          </div>
          <div class="report__title">Кадровые школы</div>
        </router-link>
        <router-link :to="{ name: 'event-plan' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>13</span>
          </div>
          <div class="report__title">План мероприятий РО РСМ на 2021 год</div>
        </router-link>
        <router-link :to="{ name: 'interaction' }" class="report__item">
          <div class="report__number">
            <img src="@/assets/img/number-leaf.svg" alt="Blue leaf">
            <span>14</span>
          </div>
          <div class="report__title">Взаимодействие с внешней средой</div>
        </router-link>
      </div>
      <div v-else class="report__slug">
        <div class="report__slug-pic">
          <img src="@/assets/img/local-icon.svg" alt="Local">
        </div>
        <div class="report__slug-title">Выберите региональную организацию</div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'Report',
  data() {
    return {
      region_result: '',
      region_options: [],
    }
  },
  computed: {
    userRegion() {
      return this.$store.state.organization
    },
    userRole() {
      return this.$store.state.userRole
    }
  },
  mounted() {
    if (this.userRegion) this.region_result = this.userRegion
    this.$store.dispatch('regOrg/GET_QUERY')
      .then(response => { this.region_options = response.data })
  },
  methods: {
    getLabel(option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    onSetOrganization() {
      this.$store.dispatch('profile/UPDATE_ORG', { region_org: this.region_result })
        .then(() => {
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Организация изменена.'
          })
          this.$store.dispatch('profile/GET_DATA')
            .then(response => {
              this.$store.commit('setOrganization', response.data.organization)
              this.$store.commit('setOrganizationName', response.data.organization_name)
            })
            .catch(() => {
              this.$notify({
                type: 'error',
                title: 'Ошибка!',
                text: 'Ошибка получения данных'
              })
            })
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Ошибка!',
            text: 'Ошибка изменения организации.'
          })
        })
    },
    onSearch(search, loading) {
      loading(true);
      this.searchDelivery(loading, search, this)
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.$store.dispatch('regOrg/GET_QUERY', search).then(response => {
        vm.region_options = response.data
        loading(false)
      })
    }, 350),
  }
}
</script>
